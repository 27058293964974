import React from "react";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/cognoser/TwoColumnWithFeaturesAndTestimonial";

import Footer from "components/cognoser/MiniCenteredFooter.js";
import AboutMe from "components/cognoser/TwoColumnWithImageAndProfilePictureReview.js"
import Services from "components/cognoser/VerticalWithAlternateImageAndText.js";
import Galery from "components/cognoser/GridWithFeaturedPost.js";
import AreasIntervencao from "components/cognoser/ThreeColWithSideImageWithPrimaryBackground.js";
import Contact from "components/cognoser/TwoColContactUsWithIllustrationFullForm.js";

export default () => {

    return (
        <AnimationRevealPage>
            <Hero roundedHeaderButton={true} />
            <section id="about">
                <AboutMe />
            </section>
            <section id="gallery">
                <Galery />
            </section>
            <section id="services">
                <Services />
            </section>
            <section id="intervention">
                <AreasIntervencao />
            </section>
            <section id="contact">
                <Contact />
            </section>

            <Footer />
        </AnimationRevealPage>
    );
}
