import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "./Header.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as QuotesLeftIconBase } from "images/quotes-l.svg"
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg"
import Logotipo from "images/cognoser/logotipo.jpg"

const Header = tw(HeaderBase)`max-w-none fixed z-50 bg-white`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-4xl`;
const Heading = tw(SectionHeading)`text-left text-custom-600 leading-snug xl:text-6xl`;
const Description = tw(SectionDescription)`mt-4 lg:text-left text-gray-700 max-w-lg text-left indent-5`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-24`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;
const Testimonial = tw.div`max-w-sm rounded-b md:rounded-none relative sm:absolute bottom-0 inset-x-0 z-20 px-8 py-6 sm:px-10 sm:py-8 bg-custom-600 text-gray-400 font-medium transform md:-translate-x-32 md:translate-y-64 text-sm leading-relaxed md:-mr-16 xl:mr-0`
const QuotesLeftIcon = tw(QuotesLeftIconBase)`w-16 h-16 md:w-12 md:h-12 absolute top-0 left-0 text-gray-100 md:text-red-500 transform translate-x-1 md:-translate-x-1/2 md:-translate-y-5 opacity-10 md:opacity-100`
const Quote = tw.blockquote``
const CustomerName = tw.p`mt-4 font-bold`
const CustomerCompany = tw.p`mt-1 text-sm text-gray-500`


export default ({
  heading = "Bem-vindo(a) à CognoSer",
  description = "No CognoSer estamos disponíveis para escutar a sua história com empatia, ajudar a lidar com as dificuldades e para acompanhar a sua caminhada em direção à melhoria do bem-estar e ao desenvolvimento pessoal.",
  buttonRounded = true,
  testimonial = {
    quote: "Adoro escutar histórias de vida. A história de cada pessoa é constituída por momentos e ciclos únicos, irrepetíveis, que podem ser mais ou menos desafiantes.  Enquanto profissional valorizo cada capítulo da história individual das pessoas que me procuram, proporcionando um espaço seguro e acolhedor para falarem da sua própria história e refletirem sobre a mesma.",
    customerName: "Ana Duarte Lucas",
    customerCompany: "Cognoser"
  }
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/#about">Sobre mim</NavLink>
      <NavLink href="/#gallery">Espaço</NavLink>
      <NavLink href="/#services">Serviços</NavLink>
      <NavLink href="/#intervention">Áreas de intervenção</NavLink>
      <NavLink href="/#contact">Contactos</NavLink>
    </NavLinks>,

  ];
  return (
    <>
      <Header links={navLinks} />
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              {/* 
              <PrimaryButton as="a" href={primaryButtonUrl} css={buttonRoundedCss}>
                {primaryButtonText}
              </PrimaryButton>
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
              */}
            </TextColumn>
            <ImageColumn>

              <Image tw="min-w-0 w-full max-w-lg xl:max-w-2xl translate-x-[-10%]" src={Logotipo} />




            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
