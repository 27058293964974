import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/star-icon.svg";

const Container = tw.div`relative bg-white -mx-8 px-8 `;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full text-custom-600`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;


const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0 text-custom-600`}
    img {
      ${tw`w-6 h-6 text-custom-600`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw.p`max-w-md text-justify indent-5 mx-auto lg:mx-0 lg:text-justify lg:max-w-none leading-relaxed text-sm sm:text-justify lg:text-lg font-medium mt-4 text-gray-700`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
}
`;

export default ({
  cards = null,
  heading = "Áreas de intervenção",
  subheading = "Em que posso ajudar",
  description = ""
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: SimpleIconImage,
      title: "Gerir estados de ansiedade e superar fobias/medos",

    },
    { imageSrc: SimpleIconImage, title: "Lidar com dificuldades relacionais e/ou conflitos interpessoais; ou outros desafios do dia a dia" },
    { imageSrc: SimpleIconImage, title: "Gerir emoções" },
    { imageSrc: SimpleIconImage, title: "Gerir sintomas depressivos e/ou melhorar a autoestima" },
    { imageSrc: SimpleIconImage, title: "Regular impulsos e comportamentos" },
    { imageSrc: SimpleIconImage, title: "Gerir o stress" },
    { imageSrc: SimpleIconImage, title: "Ajudar a resolver problemas do quotidiano" },
    { imageSrc: SimpleIconImage, title: "Avaliar e/ou reavaliar casos suspeitos de Dislexia e Perturbação de Hiperatividade/Défice de Atenção" },
    { imageSrc: SimpleIconImage, title: "Avaliar e/ou reavaliar outras questões de saúde psicológica" },
    { imageSrc: SimpleIconImage, title: "Intervir na Dislexia e na Perturbação de Hiperatividade/Défice de Atenção" },
    { imageSrc: SimpleIconImage, title: "Lidar com problemas de sono" },
    { imageSrc: SimpleIconImage, title: "Apoiar a adaptação a períodos de transição de vida " },
    { imageSrc: SimpleIconImage, title: "Apoiar no processo individual de luto" },
    { imageSrc: SimpleIconImage, title: "Lidar com os múltiplos desafios e sobrecarga inerente ao papel de cuidador(a) informal" },
    { imageSrc: SimpleIconImage, title: "Prestar apoio psicológico a pessoas LGBTQIA+" },
    { imageSrc: SimpleIconImage, title: "Apoiar num processo de autoconhecimento e desenvolvimento pessoal" },
    { imageSrc: SimpleIconImage, title: "Apoiar na tomada de decisões conscientes e ponderadas relativamente ao percurso formativo e/ou profissional" }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>


              <span className="textContainer">
                <span className="title">🎯 {card.title}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>

            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
