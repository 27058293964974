import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "./Headings.js";
import Avaliacao from "images/cognoser/avaliacao.jpg";
import Materiais1 from "images/cognoser/materiais.jpg";
import Consultas from "images/cognoser/consultas.jpg";


const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`max-w-md text-center indent-5 mx-auto lg:mx-0 lg:text-justify lg:max-w-none leading-relaxed text-sm sm:text-justify lg:text-lg font-medium mt-4 text-gray-700`;

//const Content = tw.div`mt-16`;
const Content = tw.div`max-w-screen-2xl mx-auto py-20 lg:py-2`;

const Card = styled.div(props => [
  tw`mt-24 xl:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-custom-600`;
const Title = tw.h4`text-3xl font-bold text-custom-600`;
//const Description = tw.p`mt-2 text-sm font-medium leading-loose text-justify indent-5`;
const Description = tw.p`max-w-md text-left indent-5 mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-left lg:text-lg font-medium mt-4 text-gray-700`;

const Link = tw.a`inline-block mt-4 text-sm text-custom-600 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-custom-600 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-custom-600 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-custom-600 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-custom-600 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: Consultas,
      subtitle: "Consultas de Psicologia Clínica Individuais",
      title: "Consultas de Psicologia Clínica Individuais",
      description_1:
        "O acompanhamento psicológico individual tem como objetivo ajudar indivíduos a lidar de forma adaptativa com os múltiplos desafios que enfrentam ao longo da vida, promovendo o desenvolvimento pessoal e potenciando a melhoria da sua saúde psicológica. Tem também como objetivo ajudar a ultrapassar a sintomatologia psicopatológica tendo igualmente um papel preventivo no que respeita ao desenvolvimento deste tipo de sintomatologia.",
      description_2: "O processo terapêutico é um processo colaborativo entre terapeuta e cliente, com objetivos personalizados e ajustado em função das necessidades e dificuldades específicas do/a cliente, sendo alicerçado no estabelecimento de uma boa relação terapêutica entre os intervenientes e em conhecimentos e estratégias cientificamente validadas. Se algum familiar seu ou você mesmo está a passar por problemas relacionais, stress, ansiedade, depressão ou outros problemas sociais e emocionais, ou se simplesmente se quer conhecer melhor a si próprio, não está sozinho/a. No CognoSer realizam-se consultas presenciais e on-line.",
      description_3: []
    },
    {
      imageSrc: Avaliacao,
      subtitle: "Avaliação Psicológica",
      title: "Avaliação Psicológica",
      description_1:
        "A avaliação psicológica é uma ferramenta científica muito importante e útil para a compreensão das características, experiências, necessidades e funcionamento psicológico da pessoa avaliada, podendo ajudar o/a avaliado/a (e as pessoas que o/a rodeiam) a identificar e a compreender os seus padrões relacionais e de comportamento, bem como as suas atitudes, ações, pensamentos, emoções e sentimentos. A avaliação psicológica auxilia na identificação e rastreio de problemas de saúde psicológica (como depressão, ansiedade, entre outros) tendo um papel fundamental no planeamento e realização de uma intervenção ajustada e adequada à individualidade e necessidades de cada pessoa.",
      description_2: "Realizar um processo de avaliação psicológica pode ser benéfico em qualquer altura do ciclo de vida nomeadamente quando:",
      description_3: ['- Sente alguma dificuldade em regular as suas emoções, sentimentos e comportamentos', '- Perturbação de Hiperatividade/Défice de Atenção', '- Outras questões de saúde psicológica']
    },
    {
      imageSrc: Materiais1,
      subtitle: "Orientação Vocacional",
      title: "Orientação Vocacional",
      description_1:
        "O processo de orientação vocacional é sempre feito e pensado à medida das necessidades de cada jovem, que participa ativamente no mesmo. Durante o processo é feita uma avaliação valores e interesses profissionais e uma exploração vocacional, no sentido de avaliar quais as profissões e/ou percursos académicos mais indicados para cada jovem em particular.",
      description_2: "O 3º Ciclo do Ensino Básico (7º, 8º e 9º ano), o Ensino Secundário (10º, 11º e 12º ano) e o Ensino pós Secundário/Universitário são ciclos de estudo com características próprias, existindo múltiplos percursos formativos ou profissionais pelos quais os/as jovens podem optar. Durante o processo de escolha e tomada de decisão podem surgir, naturalmente, dúvidas e incertezas, sendo fundamental os/as jovens conhecerem as várias opções formativas/profissionais que têm em função do seu percurso formativo anterior, das suas características individuais e dos seus interesses e valores profissionais, assim como refletirem criticamente sobre o seu percurso educativo/formativo e sobre que profissional gostariam de ser no futuro. Assim, realizar um processo de orientação vocacional ajuda os/as jovens a tomarem decisões mais conscientes relativamente ao seu percurso escolar/formativo e profissional. Concretamente, um processo de orientação vocacional pode ser útil:",
      description_3: ['- Em momentos de transição de ciclo (do 9º para o 10º ano e/ou do 12º para o Ensino Pós Secundário/Universitário), quando existem dúvidas relativamente ao percurso formativo a seguir, ou quando se tem certezas do caminho que se quer seguir, mas existem algumas condicionantes que dificultam o prosseguimento desse caminho (obrigando a procurar soluções formativas alternativas)', '- Quando o percurso formativo não se adequa ou deixou de ser adequado aos interesses, valores e características pessoais dos/das jovens.']
    }


  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Serviços</HeadingTitle>
          <HeadingDescription>
            No CognoSer fazemos avaliações psicológicas a crianças, jovens e adultos.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                {/*<Subtitle>{card.subtitle}</Subtitle>*/}
                <Title>{card.title}</Title>
                <Description>{card.description_1}</Description>
                <Description>{card.description_2}</Description>
                <ul>
                  {card.description_3.map((d, i) => (
                    <li><Description>{d}</Description></li>
                  ))}
                </ul>

              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
