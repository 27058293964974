import React, { useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";

import GoogleMapReact from "google-map-react";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);


const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-custom-600`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-custom-600 font-medium transition duration-300 hocus:border-custom-600 p-4`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`

export const PrimaryButton = tw.button`px-8 py-3 font-bold rounded hover:bg-custom-600 text-gray-600 hover:text-white`;
const SubmitButton = tw(PrimaryButton)`inline-block mt-4`;



emailjs.init({
  publicKey: 'r5QbDY_I9YI8aY8cp',
  // Do not allow headless browsers
  blockHeadless: true,
  blockList: {
    // Block the suspended emails
    list: ['foo@emailjs.com', 'bar@emailjs.com'],
    // The variable contains the email address
    watchVariable: 'userEmail',
  },
  limitRate: {
    // Set the limit rate for the application
    id: 'app',
    // Allow 1 request per 100s
    throttle: 100000,
  },
});


export default ({
  subheading = "Consultas",
  heading = <>Entre<span tw="text-custom-600"> em contacto </span><wbr /> connosco e marque a sua consulta.</>,
  description = "",
  submitButtonText = "Enviar",
  textOnLeft = true,
}) => {

  const form = useRef();




  const LocationPin = ({ text }) => (
    <div className="pin">
      <Icon icon={locationIcon} className="pin-icon" />
      <p className="pin-text">{text}</p>
    </div>
  );

  const sendEmail = (e) => {
    e.preventDefault();

    if (!form.current['from_email'].value || !form.current['from_name'].value || !form.current['from_subject'].value || !form.current['from_message']) {
      toast.error('Por favor, preencha todos os campos no formulário de contacto', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }
    emailjs
      .sendForm('service_2euybuf', 'template_jn9cr59', form.current, {
        publicKey: 'r5QbDY_I9YI8aY8cp',
      })
      .then(
        (result) => {
          console.log(result);
          toast.success('E-mail enviado com sucesso!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });

          form.current.reset();
        },
        (error) => {
          toast.error('Erro ao enviar e-mail', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          console.log('FAILED...', error.text);
        },
      );
  };


  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <div style={{
            display: "flex",
            flexDirection: "column",
            height: "50vh",
            width: "100%",
          }}>
            <GoogleMapReact

              bootstrapURLKeys={{ key: "AIzaSyASLOIJe7QRAVeNxL8IG0iD58uqoroR4J4" }}
              defaultCenter={{
                lat: 39.089478, // default latitude
                lng: -9.2571233, // default longitude
              }}
              defaultZoom={14}>

              <LocationPin lat={39.089478} lng={-9.2571233} text='CognoSer' />

            </GoogleMapReact>
          </div>

        </ImageColumn>

        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form ref={form} onSubmit={sendEmail}>
              <Input type="email" name="from_email" placeholder="O seu endereço de e-mail" />
              <Input type="text" name="from_name" placeholder="Nome completo" />
              <Input type="text" name="from_subject" placeholder="Assunto" />
              <Textarea name="from_message" placeholder="A sua mensagem" />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>


            <Subheading tw="mt-6">Contactos</Subheading>
            <div tw="font-medium">Psicóloga Ana Duarte Lucas</div>
            <div tw="font-normal">964 438 939</div>
            <div tw="font-normal">cognoser.psicologia@gmail.com</div>
            <div tw="font-normal">Rua Doutor Júlio César Lucas Loja 10 C 2560-333 – Torres Vedras</div>
            <Subheading tw="mt-4">Horário</Subheading>
            <div tw="font-normal">Segunda a sexta-feira das 10h às 21h</div>
            <div tw="font-normal">Sábados das 9h às 17h</div>
          </TextContent>

        </TextColumn>

      </TwoColumn>

      <ToastContainer />
    </Container>
  );
};
